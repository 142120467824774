/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    function animateOnScroll() {
        $('[animate]').each(function (i, el) {
            if (isScrolledIntoView(el) === true) {
                $(el).addClass('animated');
            }
        });
    }
    function isScrolledIntoView(elem) {
        var docViewTop = $(window).scrollTop() - 50;
        var docViewBottom = docViewTop + $(window).height();
        var elemTop = $(elem).offset().top;
        return ((elemTop <= docViewBottom) && (elemTop >= docViewTop));
    }

    $.each($(".js-text"), function () {
        if ($(this).text().length > 237) {
            $(this).after('<span class="js-read readmore link2">read more</span>');
        }
    });

    $(".js-read").on("click", function (e) {
        e.preventDefault();
        if ($(this).prev(".js-text").hasClass("ellipses")) {
            $(this).prev(".js-text").removeClass("ellipses");
            $(this).text("read less");
        } else {
            $(this).prev(".js-text").addClass("ellipses");
            $(this).text("read more");
        }
    });

    // AOS - Scroll Animations
    var aos = function () {

        AOS.init({
            duration: 800,
            disable: function () {
                return /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent);
            }
        });

        $(document).ajaxComplete(function () {
            AOS.refresh();
        });

    }
    // -------------------

    // Header Menu
    var header = function () {

        var head = $('header.primary'),
            ham = $('.hamburger'),
            nav = $('nav.primary');

        ham.on('click', function () {

            head.toggleClass('active');
            ham.toggleClass('active');
            nav.toggleClass('active');

        });
        // Scroll hide/show header
        var hero = $('.hero'),
            header = $('header.primary'),
            scroll = $(window).scrollTop();
        var lastScrollTop = 0;
        if (!hero.length || $('body').hasClass('error404') || $('body').hasClass('thank-you')) {
            header.addClass('brand');
        }
        if( !navigator.userAgent.match(/bot|googlebot|crawler|spider|robot|crawling/i )) {

            $(window).on('scroll load', function () {
                var scrollTop = $(this).scrollTop();
                if (scrollTop > lastScrollTop) {
                    // downscroll code
                    if (scrollTop > hero.offset().top + hero.height() - header.height()) {
                        head.addClass('hidden');
                    }
                    else {
                        head.removeClass('hidden');
                    }
                } else {
                    // upscroll code

                    if (!$('body').hasClass('error404') && !$('body').hasClass('thank-you')) {
                        if (scrollTop > hero.offset().top + hero.height() - header.height()) {
                            head.removeClass('hidden');
                            head.addClass('brand');
                            head.removeClass('transparent transparent--dark');
                        } else {
                            head.removeClass('brand');
                        }
                        if (scrollTop > 200) {
                            if (hero.hasClass('is-reveal')) {
                                head.addClass('transparent--dark');
                                if (head.hasClass('brand')) {
                                    head.removeClass('transparent transparent--dark');
                                }
                            }
                        }
                    }
                }

                lastScrollTop = scrollTop;

            });

        }
    };
    // -------------------

    // Header Menu
    var heroScroll = function () {

        var hero = $('.hero'),
            heroHeight = hero.outerHeight(),
            pageReveal = $('.page-reveal'),

            scrollState = 'top';

        if (hero.hasClass('is-reveal')) {

            $(window).on('scroll load DOMMouseScroll mousewheel', function () {

                var scroll = $(window).scrollTop();

                if (scroll <= heroHeight) {
                    hero.addClass("is-reveal");
                    pageReveal.removeClass('page-revealed');
                } else {
                    hero.removeClass("is-reveal");
                    pageReveal.addClass('page-revealed');
                }

                var scrollTop = $(window).scrollTop();
                var height = $(window).height() / 2;

                // Elements
                var heroBg = $('.hero-bg .bg'),
                    title = $('.hero .title'),
                    subTitle = $('.hero .sub-title'),
                    reveal = $('.inner-reveal'),
                    headerLinks = $('header.primary li a, header.primary p, header.primary .appointment a'),
                    ham = $('.hamburger'),
                    arrow = $('.hero .arrow');

                var opacityStart = (height - scrollTop) / height;
                var opacityFinish = (height + scrollTop) / height;

                if (opacityStart >= 0) {

                    heroBg.css({
                        'opacity': ((height - scrollTop) / height)
                    });
                    title.delay(1300).queue(function () {
                        $(this).css({
                            'opacity': ((height - scrollTop) / height)
                        });
                    });

                }

                reveal.css({
                    'opacity': ((scrollTop + scrollTop) / height)
                });

                if ((scroll > 200) && (scrollState === 'top')) {

                    subTitle.addClass('revealed');
                    arrow.addClass('revealed');
                    ham.addClass('revealed');
                    $('header.primary').addClass('transparent--dark');


                    if (window.matchMedia("(min-width: 992px)").matches) {

                    }

                    scrollState = 'scrolled';
                }
                else if ((scroll < 200) && (scrollState === 'scrolled')) {

                    subTitle.removeClass('revealed');
                    arrow.removeClass('revealed');
                    ham.removeClass('revealed');
                    $('header.primary').removeClass('transparent--dark');

                    if (window.matchMedia("(min-width: 992px)").matches) {


                    }

                    scrollState = 'top';
                }

            });
        }


    }
    // -------------------

    // Banner Slider
    var banner = function () {

        // Slider
        var banner = $('.banner-slider'),
            line = $('.block-banner .banner-line'),
            arrowLeft = $('.banner-side.side-left'),
            arrowRight = $('.banner-side.side-right');

        if (banner.length) {

            banner.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
                var i = (currentSlide ? currentSlide : 0) + 1;
                $('.slider-count .number.top').text(i);
                $('.slider-count .number.bottom').text(slick.slideCount);
            });


            banner.slick({
                mobileFirst: true,
                slidesToShow: 1,
                arrows: false,
                dots: false,
                fade: true,
                speed: 1000
            });

            var count = 0;

            arrowRight.on('click', function () {
                banner.slick('slickNext');
                line.css('background-position', + (count += 33) +'% 0%');
            });
            arrowLeft.on('click', function () {
                banner.slick('slickPrev');
                line.css('background-position', + (count -= 33) +'% 0%');
            });

            // Navigation Hover
            var bannerBlock = $('.block-banner'),
                sliderArrows = $('.slider-count');

            bannerBlock.on('mousemove', function (e) {

                if (window.matchMedia("(min-width: 992px)").matches) {
                    sliderArrows.offset({
                        left: e.pageX - 25,
                        top: e.pageY - 35,
                        bottom: 'auto',
                    });
                }

                bannerBlock.css('cursor', 'none');
                sliderArrows.css('opacity', '1');

            });

            bannerBlock.on('mouseleave', function (e) {

                sliderArrows.removeAttr('style');
                bannerBlock.removeAttr('style');

            });

            arrowRight.on('mouseover', function () {

                sliderArrows.find('.arrow-prev').addClass('inactive');
                sliderArrows.find('.arrow-next').removeClass('inactive');

            });
            arrowLeft.on('mouseover', function () {

                sliderArrows.find('.arrow-prev').removeClass('inactive');
                sliderArrows.find('.arrow-next').addClass('inactive');

            });

        }

    }
    // -------------------

    // Testimonials Slider
    var testimonials = function () {

        var slider = $('.testimonials-slider'),
            sliderNext = $('.testimonials .slider-next');

        if (slider.length) {

            slider.slick({
                mobileFirst: true,
                slidesToShow: 1,
                arrows: false,
                dots: false,
                fade: true,
                speed: 1000,
            });

            slider.on('afterChange', function (event, slick, currentSlide) {
                if (slick.currentSlide >= slick.slideCount - slick.options.slidesToShow - 1) {
                    slider.find('.slick-track .slick-slide:first-child').addClass('show-behind');
                }
                ;
            });

            sliderNext.on('click', function () {

                slider.slick('slickNext');

            });

        }


    }
    // -------------------

    // News Slider
    var newsSlider = function () {

        var slider = $('.latest-news-slider'),
            sliderNext = $('.latest-news .slider-next'),
            overlay = $('.latest-news .overlay-text');

        if (slider.length) {

            slider.slick({
                mobileFirst: true,
                slidesToShow: 1,
                arrows: false,
                dots: false,
                fade: false,
                speed: 1000,
            });

            sliderNext.on('click', function () {

                slider.slick('slickNext');

            });

            sliderNext.appendTo(slider);

            overlay.appendTo(slider);

        }

        if(slider.hasClass('tailored-choices-slider')) {
            var sliderPrev = $('.latest-news .slider-prev');

            sliderPrev.on('click', function() {
                slider.slick('slickPrev');
            });
            sliderPrev.appendTo(slider);
        }
    }
    // -------------------

    // News Slider
    var lookbook = function () {

        // Main
        var slider = $('.lookbook-item:not(.alt) .lookbook-images'),
            sliderNext = $('.lookbook-item:not(.alt) .slider-next'),
            sliderAlt = $('.lookbook-item.alt .lookbook-images'),
            sliderAltNext = $('.lookbook-item.alt .slider-next');

        // Preview
        var modal = $('.lookbook-modal'),
            preview = $('.lookbook-modal .lookbook-images');

        if (slider.length) {

            // Show Previous Slide on load
            slider.on('init', function (event, slick, currentSlide) {
                slider.find('.slick-active').prev('.slick-slide').addClass('slick-slide-prev');
            });

            // Show next slide
            sliderAlt.on('init afterChange', function (event, slick, currentSlide) {

                if (sliderAlt.find('.slick-slide').hasClass('slick-slide-next')) {
                    sliderAlt.find('.slick-slide').removeClass('slick-slide-next');
                }
                sliderAlt.find('.slick-active').next('.slick-slide').addClass('slick-slide-next');

            });

            slider.slick({
                mobileFirst: true,
                slidesToShow: 1,
                arrows: false,
                dots: false,
                fade: false,
                speed: 1000,
            });

            sliderAlt.slick({
                mobileFirst: true,
                slidesToShow: 1,
                arrows: false,
                dots: false,
                fade: false,
                speed: 1000,
            });

            // Show Previous slide on next
            sliderNext.on('click', function () {
                $(this).next().slick('slickPrev');
                $(this).next().find('.slick-slide-prev').each(function () {
                    $(this).removeClass('slick-slide-prev');
                })
                $(this).next().find('.slick-slide.slick-active').prev('.slick-slide').addClass('slick-slide-prev');
                //slider.slick('slickPrev');
            });
            sliderAltNext.on('click', function () {
                $(this).next().slick('slickNext');
            });

            // Modal Slider
            modal.each(function () {

                var prev = $(this).find('.slider-prev'),
                    next = $(this).find('.slider-next'),
                    slider = $(this).find('.lookbook-images');

                slider.slick({
                    mobileFirst: true,
                    slidesToShow: 1,
                    arrows: false,
                    dots: false,
                    fade: false,
                    speed: 1000,
                });

                prev.appendTo(slider);
                next.appendTo(slider);

                prev.on('click', function () {
                    slider.slick('slickPrev');
                });
                next.on('click', function () {
                    slider.slick('slickNext');
                });

            });


        }

        // Open Modal
        var btn = $('.lookbook-item .lookbook-images .open-preview');

        btn.each(function () {

            $(this).on('click', function () {

                // get id (number) of carousel
                var num = $(this).attr('id');
                // Go to correct slide
                var slideno = $(this).data('slide');

                modal.each(function () {

                    // get modal number
                    var modalNum = $(this).data('lookbook');

                    // open modal based on same number
                    if (num == modalNum) {

                        $(this).addClass('active');
                        $(this).find('.lookbook-images').slick('slickGoTo', slideno - 1);

                    }

                });

            });

        });

        // Close Modal
        var closeModal = $('.lookbook-modal .close-preview');

        closeModal.on('click', function () {

            $(this).parents('.lookbook-modal').removeClass('active');

        });


    }
    // -------------------

    $(".js-product-slider").slick({
        mobileFirst: true,
        slidesToShow: 1,
        arrows: false,
        dots: true,
        speed: 1000
    });

    var selectInputImages = function () {
        $(".js-select-init").on("change", function () {
            var optionIndex = $(this).prop('selectedIndex');

            $.each($(".js-select-group"), function () {
                var galleryIndex = $(this).index();

                if (optionIndex == galleryIndex) {
                    $(".js-select-group").removeClass("active");
                    $(this).addClass("active");
                }
            });

            $.each($(".js-product-slider"), function () {
                var galleryIndex = $(this).index();

                if (optionIndex == galleryIndex) {
                    $(".js-product-slider").removeClass("active");
                    $(this).addClass("active");
                }
            });
        });
    }

    var loaded = function () {
        $(".js-loaded").addClass("loaded");
    };

    var customScroll = function () {
        $.each($(".js-custom-scroll"), function () {
            if ($(this).outerHeight() >= 90) {
                $(this).addClass("custom-scroll");
            }
            ;
        });
    };

    var stickyElement = function () {
        if ($(window).width() > 991) {
            var windowHeight = $(window).height();
            var header = $(".header").height();
            var elemContainerHeight = $(".js-sticky-container").height();
            var elem = $(".js-sticky-elem");
            var windowTopTrigger = $(document).scrollTop();
            var windowBottomTrigger = $(document).scrollTop() + $(window).height() - header;

            if (windowBottomTrigger > elemContainerHeight) {
                elem.addClass("bottom-reached");
            } else {
                elem.removeClass("bottom-reached");
            }

            if (windowHeight <= $(".js-sticky-inner").outerHeight() + header) {
                elem.addClass("viewport-short");
                elem.height(windowHeight - header);
            } else {
                elem.removeClass("viewport-short");
                elem.height("auto");
            }
        } else {
            $(".js-sticky-elem").height("auto");
        }
    }


    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
                header();
                heroScroll();
                banner();
                testimonials();
                newsSlider();
                selectInputImages();
                loaded();
                customScroll();
                lookbook();
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                aos();
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    $(window).bind("load resize scroll", function () {
        stickyElement();
    });

    // Load Events
    $(document).ready(UTIL.loadEvents);
    $(document).ready(function() {
        animateOnScroll();
    });

})(jQuery); // Fully reference jQuery after this point.
